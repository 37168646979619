nav{
    display: flex;
    border: 1px solid black;
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    font-size: 1rem;
    background-color: white;
    border-radius: 3px;
    z-index: 100;
    padding: 0px 20px 0px 10px;


}
nav a{
    text-decoration: none;
    color: black;
}

.nav-logo-wrapper{
    align-self: center;
    height: 100%;
    width:50px;
    padding: 5px;
}
.nav-logo{
    height: 100%;
    width: 100%;
}
.nav-item, .dropdown-content-item{
    display: block;
}


@media screen and (min-width:1200px) {
    .nav-sidebar-content-wrapper{
        flex: 1;
        display: flex;
    
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .nav-items-wrapper{
        flex: 1;
        display: flex;
    
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .nav-item,.dropdown-item{
        align-self: stretch;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        position: relative;
        transition: all .3s ease;
        -webkit-transition: all 0.3s ease;
    }


    .nav-item:hover{
        transform: scale(1.05);
    }
    
    .dropdown-content{
        display: none;
        overflow-y: scroll;
        overflow-x:hidden;
        max-height: 60vh;
        box-sizing: border-box;
    
    
    }
    .dropdown-title{
        display: flex;

        align-items: bottom;
    }
    
    .drop-indicator{
        display: flex;
        margin-left: 10px;
        transition: transform ease .3s;
        -webkit-transition: all 0.3s ease;
        align-items: center;


    
    
    }
    .drop-line-1{
        background-color: black;
        width: 1px;
        height: 10px;
        transform: rotate(-45deg);
        align-self: bottom;
    }
    .drop-line-2{
        background-color: black;
        width: 1px;
        height: 10px;
        transform: rotate(45deg);
        margin-left: 6px;
    
    }
    .dropdown-item:hover .drop-indicator{
        transform: rotate(180deg);
        align-items: center;
    }
    .dropdown-item:hover .dropdown-content {
    
        font-size: 1em;
        display: block;
        position: absolute;
        z-index: 1;
        top: 100%;
        background-color: rgb(255, 253, 251);
        width: 200%;
        animation: fade-in .35s;
        border-radius: 3px;
        box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.231);
    }
    @keyframes fade-in {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    
    .dropdown-content-item{
        cursor: pointer;
        padding: 20px 20px;
        background-color: rgb(255, 255, 255);
        border-bottom: .5px solid rgba(0, 0, 0, 0.347);
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease-in;
    
    }
    .dropdown-content-item:last-child{
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .dropdown-content-item:first-child{
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .dropdown-content-item:hover{

        border-bottom: 2px solid var(--primary);
        transform: scale(1.01);
    }

    
    
    
    
}






@media screen and (max-width:1199px){

    .hide-blacked-out-cover{
        display: none;
    }
    .blacked-out-cover{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.491);
    }

    nav{
        justify-content: space-between;

    }

    .hamburger{
        position: relative;
        z-index: 100;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: center;
        cursor: pointer;

    }
    .hamburger-line-1{
        width: 30px;
        height: 2px;
        background-color: black;
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;

    }
    .hamburger-line-2{
        opacity: 1;
        width: 30px;
        height: 2px;
        background-color: black;
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;

    }
    .hamburger-line-3{
        width: 30px;
        height: 2px;
        background-color: black;
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;


    }
    .hamburger.x{
        gap:0
    }
    .hamburger.x .hamburger-line-1{
        transform: translateY(5px) rotate(-45deg) ;
        
    }
    .hamburger.x .hamburger-line-2{
        opacity: 0;

    }
    .hamburger.x .hamburger-line-3{
        transform: translateY(1px) rotate(45deg) ;

    }

    .nav-items-wrapper{
        display: block;
        position: fixed;
        right: calc(-50vw - 40px);
        top: 0;
        overflow: scroll;
        background-color: white;
        height: 100vh;
        padding: 20px;
        padding-top: 0px;
        width: 50vw;
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;
        letter-spacing: 2px;

    }


    .nav-items-wrapper.sidebar{

        right: 0;
        top: 0;

    }
    .nav-sidebar-content-wrapper{
        padding: 100px 0px;
    }
    .nav-item,.dropdown-item{
        padding: 20px 0px;
        font-size: 1.3em;
        border-bottom: 1px solid black;
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;
    }
    .nav-item:hover,.dropdown-item:hover{
        transform: scale(1.01);
        border-bottom: 1px solid var(--primary);

    }
    .dropdown-item{
        cursor: pointer;
    }
    .dropdown-title{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }
    .drop-indicator{
        position: relative;
       
        display: flex;
        align-items: center;

    }
    .drop-line-1{
        background-color: black;
        width: 15px;
        height: 2px;
    }
    .drop-line-2{
        background-color: black;
        width: 15px;
        height: 2px;
        position: absolute;
        transform: rotate(90deg);
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;

    }
    .drop-indicator.minus .drop-line-2{
        transform: rotate(0deg);

    }
    .dropdown-content{
        display: none;
    }
    .dropdown-content.sidebar{
        margin-top: 20px;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: column;

    }
    .dropdown-content-item{
        border-bottom: 1px solid rgb(113, 113, 113);
        font-size: .9em;
        padding: 20px;
        transition: ease-in .15s;
        -webkit-transition: all 0.15s ease;
    }
    .dropdown-content-item:hover{
        border-bottom: 1px solid var(--primary);
        color: rgb(86, 86, 96);
        transform: scale(.98);

    }
}