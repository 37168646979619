@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lexend+Deca:wght@100..900&display=swap');


body {
  margin: 0;
  padding:0;
  font-family:Inter,'Lexend Deca','Encode Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #ffffff;
  --primary:#0C5CA4;
  --comp:#ef8239;
  background-color: rgb(248, 248, 248);

}
a{
  text-decoration: none;
  color: inherit;
}


