.wavy-hero-wrapper{
    height: 700px;
    position: relative;
}
.wavy-hero-image{
    width: 100%;
    height: 100%;
    object-fit: cover;

}



.wavy-hero-title{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4em;
    font-weight: bold;
    color: white;
    letter-spacing: 3px;
    text-shadow: 2px 2px 0px black;
    background-color: rgba(0, 0, 0, 0.056);
    text-align: center;
    animation: hero-stretch-in 1s;
}
@keyframes hero-stretch-in {
    0%{
        letter-spacing: 20px;
        filter: blur(10px);
    }
    100%{
        letter-spacing: 3px;
        filter: blur(0px);
    }
}



.hero-waves{
position: absolute;
width: 100%;
bottom: -1px;
left: 0;
}
.hero-waves-alt{
    position: absolute;
    width: 100%;
    bottom: 5px;
    left: 0;
}
@media screen and (max-width:500px){
    .wavy-hero-wrapper{
        height: 400px;
        overflow: hidden;
    }
    .wavy-hero-title{
        font-size: 2.8em;
    }
}