.footer-container{
    min-height: 200px;
    width: 100%;
    background-color: rgb(36, 34, 34);
    color: white;
    font-size: 10px;
    margin-top: 160px;
    padding: 40px 20px;
    box-sizing: border-box;

}
.footer-top-wrapper{
    display: flex;
    justify-content:space-around;
}
@media screen and (max-width:480px) {
    .footer-top-wrapper{
        flex-direction: column;
        gap: 40px;

    }

}

.footer-inner-flex{
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
}
.footer-inner-flex a{
    margin-bottom: 10px;
    letter-spacing: 1px;
    transition: ease-in .15s;
}
.footer-inner-flex a:hover{
    transform: scale(1.04);
}
.footer-inner-flex h1{
    font-size: 2.5em;
    margin: 0;
    letter-spacing: 3px;
    margin-bottom: 10px;
}
.footer-inner-flex h1::after{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background-color: var(--primary);
}
.footer-inner-flex p{
    margin: 0;
    margin-bottom: 5px;

}


.footer-bottom-wrapper{
    text-align: center;
    margin-top: 60px;
    font-size: 1.4em;
}
.footer-bottom-wrapper span{
    color: rgb(53, 164, 254);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.169);
}