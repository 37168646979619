



/*  --------Hero--------*/

.hero-container{
    height: 800px;
    overflow: hidden;
    font-size: 25px;
    color: white;
    width: 100%;
    background-color: rgba(13, 13, 13, 0.052);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/painting/after_2.jpg');
    /* background-attachment: fixed; */
    background-position: center;
    background-size: cover;
    flex-direction: column;


}
.hero-container button{
    font-weight: bold;
    transition: ease-in .25s;
    margin: 20px 0px;
    font-size: 20px;
    border: 1px solid white;
    color: white;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary);
    letter-spacing: 2px;
    animation: introFade 1s linear;
}
.hero-container button:hover{
    background-color: rgb(0, 136, 255);
}
.hero-container p{
    margin: 0;
    text-align: center;
    animation: introFade 1s linear;

}

.hero-logo{
    max-width: 350px;
    width: 100%;
    border-radius: 10px;
    animation: introFade 1s linear;
    box-sizing: border-box;
   
}

@keyframes introFade {
    from{
        filter: blur(10px);
        opacity: 0;

    }
    to{
        filter: blur(0px);
        opacity: 1;
    }
}






/*------about--------*/
.about-container{
    width: 100%;
    overflow: hidden;


}


.about-content{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
}
.about-general{
    padding: 20px;
    max-width: 1000px;

}
.about-title{
    color: var(--primary);
    text-align: center;
    font-size: 60px;
    letter-spacing: 3px;
    font-weight: 400;
}
.about-description{
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 1.5em;
}
.advantages-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
    gap:0px;
    margin-top:40px;
    padding: 20px;

    width: 100%;
    max-width: 1000px;

    

}
.advantage{
    display: flex;

    flex-direction: column;
    align-items: center;

}
.advantage h1{
    text-align: center;
    margin: 0;
    margin-top:40px;
    
}
@media screen and (max-width:417px) {
    .about-title{
        font-size: 45px;
    }
    .advantages-section{
        grid-template-columns: 1fr;
    }

}
.advantage p{
    padding:20px;
    text-align: center;

}
.advantage-icon-container{
    background-color: var(--primary);
    padding: 20px;
    border-radius: 200px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.advantage-icon{
    width: 90%;
}
.about-banner{
    font-size: 25px;
    color: white;
    padding: 25px;
    background-color:var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    letter-spacing: 1px;
    text-align: center;
    flex-direction: column;
}
.about-banner button{
    font-weight: bold;
    transition: ease-in .25s;
    margin: 20px 0px;
    font-size: 20px;
    border: 1px solid white;
    color: white;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary);
    letter-spacing: 2px;
}
.about-banner button:hover{
    background-color: rgb(0, 136, 255);
}
.about-banner p{
    margin: 0;
}
.about-banner.dark{

    background-color:rgba(0, 0, 0, 0.357);
    flex-direction: column;
    margin: 0;
}
.about-banner.dark button{
    font-weight: bold;
    transition: ease-in .25s;
    margin: 20px 0px;
    font-size: 20px;
    border: 1px solid white;
    color: white;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary);
    letter-spacing: 2px;
}
.about-banner.dark button:hover{
    background-color: rgb(7, 149, 250);

}



/* ----------SERVICES-------------*/
.services-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-bottom: 120px;

}
.services-title{
    font-size: 60px;
    font-weight: 400;
    text-align: center;
    color: var(--primary);
    margin-bottom: 40px;
    padding: 0px 20px;
    letter-spacing: 3px;

}
@media screen and (max-width:417px){
    .services-title{
        font-size: 45px;
        
    }
    
}
.services-description{
    padding: 20px;
    max-width: 1000px;
    font-size: 20px;
    margin: 0;
    letter-spacing: .5px;
    line-height: 1.5em;
}








/* Services GRID Content*/
.our-services-title{
    font-size: 40px;
    letter-spacing: 3px;
    font-weight: 300;
}

.services-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
    padding: 20px;
    gap: 20px;
    justify-items: center;
    width: 100%;
    max-width: 1040px;
    box-sizing: border-box;
}
@media screen and (max-width:440px) {
    .services-grid{
    grid-template-columns: 1fr;

    }
}




