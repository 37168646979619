
.card{
    width: 100%;
    cursor: pointer;


}
.card-img-wrapper{
    width: 100%;
    position: relative;
    height: 300px;
    overflow: hidden;


}
@media screen and (max-width:900px) {
    .card-img-wrapper{
        height: 400px;
        

    }
}
.card-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:center;
    transition: ease-in .50s;
}

.card-img-text{
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 2em;
    letter-spacing: 3px;
    color: white;
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
    writing-mode: vertical-rl;


}
.card-text{
    padding: 20px 0px;
}
.card-text-title{
    font-size: 1.3em;
    letter-spacing: 3px;
    display: inline-block;
    transition: ease-in .30s;


}
.card-text-title::after{
    content: "";
    display: block;
    width: 110%;
    height: 4px;
    background-color: var(--primary);
    transition: ease-in .30s;
}

.card-text-desc{
    margin-top: 10px;
    font-size: .90em;
    letter-spacing: .5px;
    line-height: 20px;
}


.card:hover .card-text-title::after{
    background-color: black;
    transform: scale(1.01);
}
.card:hover .card-text-title{
    transform: scale(1.01);
}
.card:hover .card-img{
    transform: scale(1.1);
    
}