.contact-container{
    padding-top: 140px;
    padding-bottom: 180px;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    background-color: rgb(248, 248, 248);

}

.form-header-img{
  width: 80%;
  max-width: 300px;
  margin-bottom: 0px;
  max-width: 200px;
}
.form-header-img img{
  object-fit: contain;
  width: 100%;


}
.form-description{
  margin-bottom: 20px;
  width: 80%;
  max-width: 700px;


}
.form-description h1{
  letter-spacing: 3px;
  font-size: 50px;
  text-align: center;
}
.form-description p{
  line-height: 25px;
  letter-spacing: 1px;

}





.displayNone{
    display: none;
}
form{
    background-color: rgb(253, 254, 255);
    border-radius: 5px;
    border: 1px outset rgb(215, 211, 211);
    padding:40px 20px;
    width: 85%;
    max-width: 700px;
    box-shadow: 4px 2px 12px  rgba(0, 0, 0, 0.165);
}
.contact-container input[type='text'],
.contact-container input[type='tel'],
.contact-container input[type='email'],
.contact-container textarea {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    border: none;
    width: 100%;
    outline: none;
    border-bottom: 4px solid var(--primary);
    box-sizing: border-box;
}
.contact-container input:focus{
    border-bottom:4px solid black; 

}

.form-check-boxes{
    width: 100%;
    display: flex;
    flex-direction: column;
 
   font-size: 20px;
    text-align: left;
}

.form-check-boxes div {
    margin-bottom: 15px;
  }
  
  .form-check-boxes input[type='checkbox'] {
    margin-right: 10px;
    accent-color: var(--primary);

  }
  ::placeholder{
    color: rgb(66, 65, 65);
  }
  .form-check-boxes label {
    font-size: 20px;
  }
  .message-area{
    margin-top: 30px;
    font-size: 22px;
    display: flex;
    font-family:Inter;
  }
  .contact-container textarea {
    border: 1px solid black;
    margin-left: 20px;
    width: 100%;
    padding: 0px;
    font-family: Inter;
    padding: 20px;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 5px;


  }
  .form-btn{
    font-weight: bold;
    transition: ease-in .25s;
    margin: 20px 0px;
    font-size: 20px;
    border: 1px solid white;
    color: white;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary);
    letter-spacing: 2px;
  }
  .form-btn:hover{
    background-color: rgb(7, 149, 250);
  }
  @media screen and (max-width:700px) {
    .message-area{
        flex-direction: column;
      }
      .contact-container textarea{
        margin: 0px;
        margin-top: 20px;

      }
  }

