.services-page-wrapper{
    padding-bottom: 0px;
    background-color: rgb(248, 248, 248);
}

/*------Hero Content------*/

.services-hero{
    height: 600px;
    position: relative;
}
.services-hero-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.services-hero-content{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.421);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.services-hero-content h1{
    margin: 0;
    font-size: 4em;
    letter-spacing: 5px;
    color: rgb(255, 255, 255);
    text-shadow: 2px 3px 0px black;
    position: relative;
  
}

.services-hero-content p{
    color: rgba(255, 255, 255, 0.83);
    text-shadow: 10px 10px 30px black;
    letter-spacing: 3px;
    font-size: 1.2em;
    text-align: center;
    max-width: 900px;
    position: relative;
}
.services-hero-content p,.services-hero-content h1{
    animation: introFade .75s linear;
}
@keyframes introFade {
    from{
        filter: blur(10px);
        opacity: 0;
        top: 300px;


    }
    to{
        filter: blur(0px);
        opacity: 1;
        top: 0px;

    }
}





.about-services-wrapper{

    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

}
.about-services-wrapper div{
    padding: 60px 20px;
    max-width: 1000px;
}
.about-services-wrapper div h1{
    letter-spacing: 1px;
    color: rgb(45, 43, 43);
    text-align: center;
    font-size: 3em;
    font-weight: 500;

}
.about-services-wrapper div h1::after{

    content: "";
    width: 100%;
    height: 5px;
    background-color:var(--primary);
    display: block;
    margin-top: 20px;
    border-radius: 5px;
    
}
.about-services-wrapper div p{
    line-height: 30px;
    color: rgb(75, 72, 72);
    font-size: 1.2em;

}




/*-------Service---------*/
.service-main-wrapper{
    padding: 10px;
    display: flex;
    justify-content: center;
}
.service-main-wrapper:nth-child(odd) .service-content-wrapper{
    right: 0;
    border-radius:0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: -4px 2px 12px  rgba(0, 0, 0, 0.537);

    text-align: left;
}
.service-wrapper{
    height: 700px;
    min-height: 600px;
    position: relative;
    border-radius: 10px;
    max-width: 1920px;
    width: 100%;
    max-height: 1080px;
    overflow: hidden;
    text-align: right;



}
.service-content-wrapper{
    position: absolute;
    top: 70px;
    background-color: rgba(0, 0, 0, 0.735);
    box-shadow: 4px 2px 12px  rgba(0, 0, 0, 0.531);
    max-width: 600px;
    padding: 40px 20px;
    color: white;
    height: 70%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.service-component-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: ease-in .5s;

}
.service-wrapper:hover .service-component-img{
    transform: scale(105%);


}
.service-content-wrapper h1{
    margin-top: 0;
    text-shadow: 2px 3px 0px black;
    font-size: 3em;

}
.service-content-wrapper h1::after{
    content: "";
    width: 100%;
    height: 3px;
    background-color:var(--primary);
    display: block;
    margin-top: 20px;
}
.service-content-wrapper p{
    line-height: 30px;
    text-shadow: 2px 3px 19px rgb(0, 0, 0);
    font-size: 1.3em;
}

.service-content-wrapper button{
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 2px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    background-color: var(--primary);
    color: white;
    transition: ease-in .3s;
    margin-top: 20px;
}

.service-content-wrapper button:hover{
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.424);
}

@media screen and (max-width:800px) {
    .service-main-wrapper{
        padding-bottom: 40px;

        
    }
    .service-wrapper{
        min-height: 0px;
        max-height: 500px;
        overflow: hidden;
        border-radius: 0px;
        text-align: initial;
        border-top: 5px solid rgb(0, 0, 0);
        border-bottom: 5px solid rgb(0, 0, 0);
        border-left: 5px inset rgb(43, 42, 42);
        border-right: 5px inset rgb(43, 42, 42);
        border-radius: 3px;


    
    }
    .service-content-wrapper{
        height: 100%;
        top:0;
        box-sizing: border-box;
        left:0;
        max-width: 100%;
        background-color:rgba(0, 0, 0, 0.267);
        border-radius:0px;
    }
    .service-component-img{
        border-radius: 0px;
        transition: ease-in .3s;

        
    }
    .service-wrapper:hover .service-component-img{
        filter: blur(1px);

    }
    .service-content-wrapper h1{
        font-size: 2.9em;

    }
    .service-content-wrapper p{
        line-height: 30px;
        font-size: 1.3em;
    }
    .service-main-wrapper:nth-child(odd) .service-content-wrapper{
        border-radius:0px;


    }
    .more-btn-container{
        display: flex;
        justify-content: end;
    }
}
@media screen and (max-width:550px){
    .service-wrapper{
        max-height: 600px;

    
    }
    .services-hero-content h1{
        font-size: 2.8rem;
    
      
    }
    .about-services-wrapper div h1{
        font-size: 2.8rem;
    }
    .service-content-wrapper h1{
        font-size: 2.3em;
    }
}