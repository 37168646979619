.service-info-section-wrapper{
    padding: 40px;
    display: flex;
    justify-content: center;
}
.service-info-section-wrapper div{
max-width: 1400px;
}
.service-info-section-wrapper h1{
    font-size: 4em;
    display: inline-block;
}
.service-info-section-wrapper h1::after{
    content: "";
    margin-top: 10px;
    width: 100%;
    height: 3px;
    background-color: var(--primary);
    display: block;
    border-radius: 5px;

}
.service-info-section-wrapper p{
    line-height: 30px;
    font-size: 20px;
    letter-spacing: .5px;
}

.service-info-section-pic{
    width: 300px;
    height: 300px;
    float: right;
    object-fit: cover;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.282);
}

@media screen and (max-width:750px) {
    .service-info-section-pic{
        float: initial;
        display: block;
        width: 100%;
        margin-left: 0px;
        margin-bottom: 50px;

        height: 100%;
        max-height: 300px;
        border-radius: 10px;
    }

}
@media screen and (max-width:500px) {
    .service-info-section-wrapper h1{
        font-size: 55px;
    }
    .service-info-section-wrapper p{
        font-size: 16px;
    }
}