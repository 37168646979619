.service-gallery-title{
    padding: 40px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 48px;
    max-width: 1400px;
    font-weight: 400;
    letter-spacing: 4px;
    text-align: center;

}
.service-gallery-container{
    padding: 40px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
}
.service-gallery-grid{
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px,1fr));
    width: 100%;
    gap: 40px;
}
.service-image-grid-item{
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}
.service-grid-image-container{
    width: 100%;
    height: 320px;
    border-radius: 5px;
    overflow: hidden;


}
.service-image-grid-item img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: ease-in 1s;
    cursor:zoom-in;
}
.service-image-grid-item:hover img{
    transform: scale(1.2)
}





.service-image-grid-item img.top{
    object-position: top;


}
.service-image-grid-item p{
    position: absolute;
    letter-spacing: 3px;
    top: 0;
    writing-mode: vertical-rl;
    font-size: 30px;
    color: white;
    margin-top:30px ;
    text-shadow: 3px 2px 0px rgba(0, 0, 0, 0.88);

}
.service-grid-title{
    font-size: 1.3em;
    flex:1;
    letter-spacing: 3px;
    display: inline-block;
    transition: ease-in .30s;
    margin: 10px 0px 10px 0px;
}
.service-image-grid-item::after{
    content: "";
    display: block;
    width: 100%;
    margin-top: 10px;
    height: 4px;
    background-color: var(--primary);
    transition: ease-in .30s;
    margin: 0;
    border-radius: 5px;
}
.service-image-grid-item:hover::after{
    background-color: black;
}




@media screen and (max-width:400px) {
    .service-gallery-container,.service-gallery-title{
        padding: 10px;
        
    }
    .service-gallery-grid{
        max-width: 1400px;
        display: grid;
        grid-template-columns: 1fr;

    }
}
@media screen and (max-width:500px) {
    .service-gallery-title{
        font-size: 50px;
    }
}




.full-screen-img-container{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.779);
}
.fs-x{
    display: flex;
    justify-content: flex-end;
}
.fs-x-icon{

    padding: 20px;
    cursor: pointer;
}
.full-screen-img-container img{
    width: 100%;
    height: 80%;
    object-fit:contain;
    box-sizing: border-box;

}
@media screen and (max-width:1000px) {
    .full-screen-img-container{
        overflow: hidden;
        padding: 10px 10px;

    }
}

